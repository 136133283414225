*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

html {
  font-size: 40%;
  scroll-behavior: smooth; }
  @media only screen and (min-width: 24.375em) {
    html {
      font-size: 62.5%; } }

body {
  font-family: "roboto", Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  overflow-x: hidden; }

.body-inactive {
  overflow-y: hidden; }

.legal-list {
  margin-left: 2.6em; }

.heading {
  color: #1E798D;
  font-size: 4.2rem;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 48em) {
    .heading {
      font-size: 4.8rem; } }
  .heading__container {
    max-width: max-content;
    margin-bottom: 4rem; }
  .heading__line {
    border-bottom: 0.6rem solid #f77b2f;
    width: 40%; }
  .heading__center {
    display: flex;
    justify-content: center; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif; }

.t--secondary {
  color: #f77b2f;
  font-weight: 800; }

.btn {
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 2.4rem;
  font-weight: 700;
  max-width: 33.6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.5rem;
  border-radius: 1.5rem;
  transform: translateY(0);
  transition: all ease-in .2s; }
  .btn > *:not(:last-child) {
    margin-right: 2rem; }
  .btn:hover {
    transform: translateY(-0.4rem); }
  .btn:focus {
    transform: translateY(0rem); }
  .btn--solid {
    background-color: #f77b2f;
    border: none; }
  .btn--hollow {
    background-color: transparent;
    border: 0.4rem solid #f77b2f; }
  .btn__icon {
    width: 3.7rem;
    height: 2.6rem; }
    @media only screen and (min-width: 48em) {
      .btn__icon {
        width: 3.7rem;
        height: 2.6rem; } }

:root {
  --primary-blue: #6CE7D6;
  --primary-blue-dark: #08AEEA;
  --red-light: #F44881;
  --red-dark: #EC4551;
  --green-light: #72FFA1;
  --green-dark: #55C57A;
  --purple-light: #B372BD;
  --purple-dark: #8B60ED;
  --grey-dark: #434343;
  --grey-light: #f8f8f8;
  --grey: #858585;
  --horizontal-gutter: 4rem;
  --box-shadow: 0 0.5rem .8rem rgba(0,0,0,0.2); }

.card__info {
  position: relative;
  width: 100%;
  height: 12.2rem;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  cursor: pointer; }
  .card__info:not(:last-child) {
    margin-bottom: 4rem; }
  @media only screen and (min-width: 90em) {
    .card__info {
      max-width: 100%; } }
  .card__info--icon {
    position: absolute;
    right: 22px;
    top: -22px;
    z-index: 3; }

.card__offset {
  position: relative; }
  .card__offset--front {
    position: relative;
    z-index: 2; }
  .card__offset--back {
    background-color: #1E798D;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    z-index: 1;
    border-radius: 0.9rem; }
  .card__offset--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0.1rem solid #1E798D;
    background-color: white;
    border-radius: 0.9rem;
    z-index: 2;
    padding: 2rem 1.6rem; }
  .card__offset--content {
    max-width: 100%; }
  .card__offset--title {
    color: #1E798D;
    font-size: 2rem;
    font-weight: 500;
    font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    margin-bottom: 0.8rem;
    text-align: center; }
    @media only screen and (min-width: 90em) {
      .card__offset--title {
        margin-bottom: 1.2rem;
        font-size: 3.2rem; } }
  .card__offset--text {
    font-size: 1.4rem; }
    @media only screen and (min-width: 90em) {
      .card__offset--text {
        font-size: 2rem; } }

.member {
  text-align: center; }
  .member__img {
    margin-bottom: 4rem; }
  .member__name {
    font-size: 3.2rem;
    color: #f77b2f;
    margin-bottom: 2rem; }
  .member__title {
    color: #424B5A;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 2rem; }
  .member__description {
    font-size: 2rem; }

.tab {
  display: flex;
  max-width: 70rem;
  margin: 0 auto;
  align-self: center;
  text-transform: capitalize;
  margin-bottom: 4rem; }
  .tab__fill {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    color: #f77b2f;
    border-radius: .5rem; }
  .tab__hollow {
    font-size: 1.8rem;
    color: var(--grey);
    margin-bottom: 2rem; }
    .tab__hollow--active {
      color: var(--grey-dark-text); }
  .tab__item {
    width: 100%;
    border-radius: .5rem; }
  .tab__link {
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
    cursor: pointer; }
    .tab__link:link, .tab__link:visited {
      text-decoration: none;
      color: var(--grey-dark-text); }
    .tab__link:hover {
      background-color: #f77b2f;
      color: white; }
    .tab__link--active {
      color: white !important;
      background-color: #f77b2f !important; }

.event__item:not(:last-child) {
  margin-bottom: 2rem; }

.event__text {
  margin-bottom: 1rem; }

.event__link {
  color: #f77b2f;
  text-transform: capitalize;
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
  display: inline-block; }
  .event__link:after {
    display: block;
    content: '';
    border-bottom: solid 3px #f77b2f;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
    padding: .3rem; }
  .event__link:hover:after {
    transform: scaleX(1); }

.container {
  flex: 1;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 48.0rem !important; }
  @media only screen and (min-width: 36em) {
    .container {
      max-width: 56.0rem !important; } }
  @media only screen and (min-width: 48em) {
    .container {
      max-width: 100rem !important; } }
  @media only screen and (min-width: 90em) {
    .container {
      max-width: 143.4rem !important; } }
  .container-fluid {
    width: 100%;
    padding: 0;
    margin: 0 auto; }

.row, .row-sm, .row-sm-reverse, .row-md, .row-md-reverse, .row-lg, .row-lg-reverse, .row-xl, .row-xl-reverse {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .row [class^="col-"], .row-sm [class^="col-"], .row-sm-reverse [class^="col-"], .row-md [class^="col-"], .row-md-reverse [class^="col-"], .row-lg [class^="col-"], .row-lg-reverse [class^="col-"], .row-xl [class^="col-"], .row-xl-reverse [class^="col-"] {
    padding: 1.5em; }
  .row-reverse {
    flex-direction: row-reverse; }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-1 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (1/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-2 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (2/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-3 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (3/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-4 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (4/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-5 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (5/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-6 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (6/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-7 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (7/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-8 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (8/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-9 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (9/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-10 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (10/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-11 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (11/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm {
      flex-direction: row; }
      .row-sm .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 24.375em) {
    .row-sm-reverse {
      flex-direction: row-reverse; }
      .row-sm-reverse .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md {
      flex-direction: row; }
      .row-md .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 36em) {
    .row-md-reverse {
      flex-direction: row-reverse; }
      .row-md-reverse .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg {
      flex-direction: row; }
      .row-lg .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 48em) {
    .row-lg-reverse {
      flex-direction: row-reverse; }
      .row-lg-reverse .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl {
      flex-direction: row; }
      .row-xl .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }
  @media only screen and (min-width: 90em) {
    .row-xl-reverse {
      flex-direction: row-reverse; }
      .row-xl-reverse .col-12 {
        min-width: 0;
        width: 100%;
        flex: 0 0 calc( (12/ 12) * 100%); } }

.flex {
  display: flex; }

.header {
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 8rem; }
  .header__sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 15; }

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  padding: 0 1.5em; }
  .nav__menu {
    display: block; }
    @media only screen and (min-width: 48em) {
      .nav__menu {
        display: none; } }
  .nav__list {
    display: none;
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 1000;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media only screen and (min-width: 48em) {
      .nav__list {
        display: flex;
        flex-direction: row;
        height: 8rem;
        width: auto;
        position: relative; } }
    .nav__list--open {
      display: flex !important; }
  .nav__item {
    font-size: 6.4rem; }
    .nav__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10rem; }
      @media only screen and (min-width: 48em) {
        .nav__item:not(:last-child) {
          font-size: 2.4rem;
          margin-bottom: 0;
          margin-right: 3rem; } }
    @media only screen and (min-width: 48em) {
      .nav__item {
        font-size: 2.4rem; } }
  .nav__link {
    text-decoration: none;
    color: #1E798D;
    font-size: 4.8rem;
    text-transform: capitalize; }
    .nav__link:hover {
      color: #f77b2f; }
    @media only screen and (min-width: 48em) {
      .nav__link {
        font-size: 1.6rem; } }
  .nav__cta {
    text-decoration: none;
    background-color: #f77b2f;
    color: white;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14.4rem;
    font-size: 1.6rem; }
    .nav__cta--container {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center; }
      .nav__cta--container > * :not(:last-of-type) {
        margin-right: 4rem; }
    .nav__cta--link {
      color: #f77b2f;
      text-decoration: none;
      font-size: 1.6rem;
      margin-left: 2rem; }

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8rem;
  margin-top: -8rem;
  background-image: linear-gradient(to right top, rgba(32, 33, 41, 0.84), rgba(30, 121, 141, 0.84)), url(../src/assets/images/helena-lopes-PGnqT0rXWLs-unsplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media only screen and (min-width: 48em) {
    .banner {
      height: 68rem; } }
  .banner__content {
    max-width: 84rem;
    width: 100%; }
  .banner__h1 {
    font-size: 4.2rem;
    color: white;
    font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    margin-bottom: 4rem;
    text-align: center; }
    @media only screen and (min-width: 48em) {
      .banner__h1 {
        font-size: 5.8rem;
        text-align: left; } }
  .banner__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media only screen and (min-width: 48em) {
      .banner__btn {
        flex-direction: row;
        justify-content: flex-start; } }
    .banner__btn > *:not(:last-child) {
      margin-bottom: 4rem;
      margin-right: 0; }
      @media only screen and (min-width: 48em) {
        .banner__btn > *:not(:last-child) {
          margin-right: 4rem;
          margin-bottom: 0; } }

.overview {
  line-height: 150%;
  margin-bottom: 8rem; }
  .overview__h4 {
    font-size: 3.6rem;
    margin-bottom: 4rem; }
  .overview__text:not(:last-child) {
    margin-bottom: 4rem; }

.team {
  margin-bottom: 8rem; }
  .team__container {
    position: relative; }
  .team__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1E798D;
    padding: 6rem; }
    @media only screen and (min-width: 90em) {
      .team__right {
        padding: 0;
        padding-left: 6rem;
        height: 78.6rem;
        max-width: 82.4rem; } }
    .team__right--container {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media only screen and (min-width: 90em) {
        .team__right--container {
          height: 78.6rem;
          max-width: 46rem; } }
  .team__text {
    color: white;
    margin-bottom: 8rem; }
  .team__img {
    max-width: 82.4rem;
    margin-bottom: 8rem; }
    @media only screen and (min-width: 90em) {
      .team__img {
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%); } }

.events {
  margin-bottom: 8rem; }

.intro__content:not(:last-child) {
  margin-bottom: 8rem; }

.intro__img {
  max-width: 100%; }

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 8rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 6rem 0;
  width: 60%; }

.result__tab {
  margin: 6rem 0; }

.result__ctas {
  display: flex;
  justify-content: space-between; }

.result__next {
  align-self: flex-end;
  flex: 1;
  display: flex;
  justify-content: flex-end; }

.result__prev {
  align-self: flex-start;
  flex: 1;
  display: flex;
  justify-content: flex-start; }

.table {
  width: 100%;
  empty-cells: hide;
  padding: 0 2rem;
  border-collapse: collapse;
  border-spacing: 0 20px;
  font-size: 1.6rem;
  margin-top: 4rem; }
  .table__container {
    max-width: 100%;
    overflow-x: scroll; }

.th1 {
  background-color: #1E798D;
  color: white;
  height: 10.8rem; }
  .th1 > *:first-child {
    border-top-left-radius: 20px; }
  .th1 > *:last-child {
    border-top-right-radius: 20px; }

.th2 {
  background-color: #424B5A;
  color: white;
  height: 5rem; }

td {
  padding: 2rem; }

.colgroup:not(:first-child) {
  border-left: 14px solid white; }
